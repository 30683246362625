import React from 'react';
import { lazy } from 'react';
import Head from 'next/head';
import { HeroSkeleton } from '~/components/shared/Skeleton';
import { MonarchSlot } from '@redventures/cohesion-utils-react';

const CompetitorComparison = lazy(() =>
  import('~/components/base/v1/Competitor Comparison')
);
const FinalEpq = lazy(() => import('~/components/base/v1/Final EPQ'));
const ValueProps = lazy(() => import('~/components/base/v1/Value Props'));
const ACP_Banner = lazy(() => import('~/components/base/v1/ACP Banner'));
const WholeWifiModule = lazy(() =>
  import('~/components/base/v1/Whole Home WiFi')
);
const EducationModule = lazy(() =>
  import('~/components/base/v1/Education Module')
);
const Banner = lazy(() => import('~/components/base/v1/US News'));
const MirHero = lazy(() => import('~/components/base/v1/MIR Hero'));

const Home = () => {
  return (
    <>
      <Head>
        <title>
          Satellite Internet | 1-855-858-5504 | Find Your Satellite Internet
          Provider!
        </title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta
          name='description'
          content='Satellite Internet is fast, affordable and always connected! Visit to learn more about satellite Internet today.'
        />
      </Head>

      {/* Hero Placement */}
      <MonarchSlot slotId='homeHero' skeleton={HeroSkeleton}>
        <MirHero />
      </MonarchSlot>

      {/* Monarch Slot Section 1 */}
      <MonarchSlot slotId='homeSection1'>
        <ValueProps />
      </MonarchSlot>

      {/* Monarch Slot Section 2 */}
      <MonarchSlot slotId='homeSection2'>
        <CompetitorComparison />
      </MonarchSlot>

      {/* Monarch Slot Section 3 */}
      <MonarchSlot slotId='homeSection3'>
        <ACP_Banner />
      </MonarchSlot>

      {/* Monarch Slot Section 4 */}
      <MonarchSlot slotId='homeSection4'>
        <WholeWifiModule />
      </MonarchSlot>

      {/* Monarch Slot Section 5 */}
      <MonarchSlot slotId='homeSection5'>
        <EducationModule />
      </MonarchSlot>

      {/* Monarch Slot Section 6 */}
      <MonarchSlot slotId='homeSection6'>
        <Banner />
      </MonarchSlot>

      {/* Monarch Slot Section 7 */}
      <MonarchSlot slotId='homeSection7'>
        <FinalEpq />
      </MonarchSlot>

      {/* Monarch Slot Section 8 */}
      <MonarchSlot slotId='homeSection8' />

      {/* Monarch Slot Section 9 */}
      <MonarchSlot slotId='homeSection9' />

      {/* Monarch Slot Modal Section */}
      <MonarchSlot slotId='homeModal' />
    </>
  );
};

export default Home;
